@font-face {
  font-family: SplineSans;
  src: url("SplineSans-Bold.cab93581.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: SplineSans;
  src: url("SplineSansMono-Regular.c404aaa2.ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  touch-action: none;
  font-family: SplineSans, sans-serif;
  overflow: hidden;
}

/*# sourceMappingURL=index.4bf2fdc2.css.map */
